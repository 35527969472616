import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../fragments/MeetingFragment.generated';
import { VisitorFragmentFragmentDoc } from '../fragments/VisitorFragment.generated';
export type GetMeetingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMeetingsQuery = { __typename?: 'Query', currentInmate: { __typename?: 'Inmate', id: string, meetings: { __typename?: 'MeetingConnection', edges: Array<{ __typename?: 'MeetingEdge', node: { __typename?: 'Meeting', id: string, meetingType: Types.MeetingType, status: Types.MeetingStatus, participantComment: Types.ParticipantComment | null, visitors: Array<{ __typename?: 'Visitor', id: string, firstName: string, lastName: string, email: string, profileImageUrl: string | null }>, call: { __typename?: 'Call', status: Types.CallStatus } | null, interval: { __typename?: 'Interval', startAt: number, endAt: number, seconds: number | null } } }> } } | null };


export const GetMeetingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMeetings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentInmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"meetings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"call"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetMeetingsQuery, GetMeetingsQueryVariables>;