import {
  CloseOutlined,
  InfoCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Modal, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetVoiceCallTimesDocument } from "src/graphql/queries/GetVoiceCallTimes.generated";
import {
  formatDate,
  MAX_VOICE_CALL_DURATION_PERIOD_MINUTES,
  millisecondsToHoursMinutesSecondsDigitsLabel,
  millisecondsToHoursMinutesSecondsTextLabel,
  reportApolloError,
} from "src/utils";
import PrivacyNotice from "./PrivacyNotice";
import Rules from "./Rules";

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  callIsLoading: boolean;
}

const StartVoiceCallModal: React.FC<Props> = ({
  onConfirm,
  onCancel,
  isOpen,
  callIsLoading,
}) => {
  const { t } = useTranslation("voiceCalls");

  const { data } = useQuery(GetVoiceCallTimesDocument, {
    onError: (err) =>
      reportApolloError(err, "StartVoiceCallPopover/GetVoiceCallTimesDocument"),
  });

  const [isHowMinutesWorkPanelOpen, setIsHowMinutesWorkPanelOpen] = useState(0); // number instead of bool because Collapse panel operates by numeric key
  const [
    isReducedRemainingMinutesNoteOpen,
    setIsReducedRemainingMinutesNoteOpen,
  ] = useState(true);

  const remainingTimeQuota =
    data?.currentInmate?.remainingVoiceCallTimeQuota || 0;
  const remainingVoiceCallDuration = Math.min(
    remainingTimeQuota,
    data?.currentInmate?.remainingVoiceCallDuration || 0
  );
  const resetMinutes =
    MAX_VOICE_CALL_DURATION_PERIOD_MINUTES -
    (data?.currentInmate?.facility.maxVoiceCallDuration || 0) / (1000 * 60);

  const remainingTimeQuotaDigitsFormatted =
    millisecondsToHoursMinutesSecondsDigitsLabel(remainingTimeQuota);

  const remainingDurationTextFormatted =
    millisecondsToHoursMinutesSecondsTextLabel(remainingVoiceCallDuration);
  const remainingDurationDigitsFormatted =
    millisecondsToHoursMinutesSecondsDigitsLabel(remainingVoiceCallDuration);
  const outOfDurationMinutes = remainingVoiceCallDuration === 0;
  const outOfQuotaMinutes = remainingTimeQuota === 0;
  const callDisabled = outOfDurationMinutes || outOfQuotaMinutes;
  const isReducedRemainingMinutes =
    0 < remainingVoiceCallDuration &&
    remainingVoiceCallDuration <
      (data?.currentInmate?.facility.maxVoiceCallDuration || 0);

  const reducedRemainingMinutesNote = () => (
    <div className="mx-4 mt-4 p-4 border rounded border-yellow-300 bg-yellow-50 flex">
      <div>
        <InfoCircleFilled className="text-xl text-yellow-300 pr-2" />
      </div>
      <div>
        {t(
          "startVoiceCallModal.Heads up — you have only {{minutes}} available for this call, shorter than usual__",
          {
            minutes: remainingDurationTextFormatted,
          }
        )}
      </div>
      <div>
        <CloseOutlined
          className="text-xl text-gray-300 pl-4 cursor-pointer"
          onClick={() => setIsReducedRemainingMinutesNoteOpen(false)}
        />
      </div>
    </div>
  );

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        setIsReducedRemainingMinutesNoteOpen(true); // Make the warning visible again for the next time the modal opens.
        onCancel();
      }}
      title={t(
        "startVoiceCallModal.Please review the following before starting your voice call"
      )}
      okText={t("startVoiceCallModal.Agree & Call")}
      cancelText={
        callDisabled
          ? t("startVoiceCallModal.Close")
          : t("startVoiceCallModal.Cancel")
      }
      width={712}
      onOk={() => {
        onConfirm();
      }}
      closable={!callIsLoading} //Closing the modal while the call loads wouldn't cancel the connection process, leading to unexpected behavior (the call still connects.)
      maskClosable={!callIsLoading}
      okButtonProps={{ hidden: callDisabled, loading: callIsLoading }}
      cancelButtonProps={{ disabled: callIsLoading }}
      centered={true}
      style={{ paddingBottom: "0px" }}
      bodyStyle={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="overflow-y-scroll px-6" style={{ maxHeight: "500px" }}>
        <span className="text-xs text-black opacity-45">
          {t("startVoiceCallModal.VOICE CALL MINUTES")}
        </span>
        <div className="flex flex-col items-center">
          <div className="flex w-full gap-2">
            <div className=" w-1/2 flex flex-col items-center pb-1 border bg-gray-50 border-gray-100 p-6 rounded-md">
              <div>
                <span className="text-2xl font-medium">
                  {remainingDurationDigitsFormatted}
                </span>
              </div>
              <div>
                <span className="text-sm text-black opacity-45">
                  {t("startVoiceCallModal.Available now")}
                </span>
              </div>
              <div>
                <div className="ml-auto mr-auto">
                  {data?.currentInmate?.remainingVoiceCallDurationResetTime && (
                    <div className="flex text-xs text-blue-600">
                      <span className="text-xs text-blue-600">
                        {t("startVoiceCallModal.Resets at {{time}}", {
                          time: formatDate(
                            new Date(
                              data.currentInmate.remainingVoiceCallDurationResetTime
                            ),
                            "timea"
                          ),
                        })}
                      </span>
                      <Tooltip
                        title={t(
                          "startVoiceCallModal.You will receive more minutes for calls {{resetMinutes}} minutes after your most recent call__",
                          { resetMinutes }
                        )}
                      >
                        <QuestionCircleOutlined className="pl-1 pt-0.5" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className=" w-1/2 flex flex-col items-center pb-1 border bg-gray-50 border-gray-100 p-6 rounded-md">
              <div>
                <span className="text-2xl font-medium">
                  {remainingTimeQuotaDigitsFormatted}
                </span>
              </div>

              <div className="pb-1">
                <span className="text-sm text-black opacity-45">
                  {t("startVoiceCallModal.Remaining this week")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-4">
          <span className="text-s text-black opacity-65">
            {t(
              "startVoiceCallModal.This is the total time for all of your calls, not for a particular contact"
            )}
          </span>
        </div>
        <Rules
          isHowMinutesWorkPanelOpen={isHowMinutesWorkPanelOpen}
          setIsHowMinutesWorkPanelOpen={setIsHowMinutesWorkPanelOpen}
          maxVoiceCallDuration={
            data?.currentInmate?.facility.maxVoiceCallDuration || 0
          }
          maxTimeQuota={
            data?.currentInmate?.facility.defaultVoiceCallTimeQuota || 0
          }
          resetMinutes={resetMinutes}
        />
        {outOfQuotaMinutes && (
          <div className="m-4 p-4 border rounded border-red-300 bg-red-50">
            {t(
              "startVoiceCallModal.You’ve already used all of your voice call minutes this week__ Don’t worry though! Your weekly limit resets every Monday morning__"
            )}
          </div>
        )}
        {!outOfQuotaMinutes && <PrivacyNotice />}
        {isReducedRemainingMinutes &&
          isReducedRemainingMinutesNoteOpen &&
          reducedRemainingMinutesNote()}
      </div>
    </Modal>
  );
};

export default StartVoiceCallModal;
