import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../fragments/MeetingFragment.generated';
import { VisitorFragmentFragmentDoc } from '../fragments/VisitorFragment.generated';
export type GetMeetingQueryVariables = Types.Exact<{
  meetingId: Types.Scalars['ID'];
}>;


export type GetMeetingQuery = { __typename?: 'Query', meeting: { __typename?: 'Meeting', id: string, meetingType: Types.MeetingType, status: Types.MeetingStatus, participantComment: Types.ParticipantComment | null, visitors: Array<{ __typename?: 'Visitor', id: string, firstName: string, lastName: string, email: string, profileImageUrl: string | null }>, interval: { __typename?: 'Interval', startAt: number, endAt: number, seconds: number | null } } };


export const GetMeetingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMeeting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetMeetingQuery, GetMeetingQueryVariables>;