import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { showToast } from ".";

export const reportApolloError = (error: ApolloError, key: string) => {
  if (process.env.NODE_ENV === "development")
    alert(`Dev mode alert: ${error.message}`);
  Sentry.captureException(error);
  showToast(
    key,
    "We're sorry! An unexpected error occurred. Our team has been notified.",
    "error"
  );
};
